<template>
  <div>
    <b-sidebar
      id="view-materials-details-store"
      ref="modal"
      v-model="modalShow"
      header-class="headerModalToggle"
      right
      shadow
      size="l"
      width="1000px"
      :title="`${orderMovementNumber} - ${$t('MaterialVariants')}`"
      no-close-on-esc
      @click="hideModal"
      @close="onCancelToggle"
    >
      <template #title>
        <strong style="color: #101828;">{{ $t('orderMovementNumber') }} - {{ orderMovementNumber }} : {{ $t('MaterialVariants') }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px;"
          class="fa fa-close"
          @click="onCancelToggle"
        />
      </template>
      <div
        v-for="(item,index) in getManuStoreDetails"
        :key="index"
        style="padding:20px;"
      >
        <p style="font-weight: 600">
          {{ $t('Order') }} - {{ item.orderNumber }} , {{ $t('ArticleName') }} - {{ item.articleNumber }}
        </p>
        <table
          class="team_table"
        >
          <thead>
            <tr>
              <th>
                {{ $t('MaterialVariantName') }}
              </th>
              <th>
                {{ $t('Unit') }}
              </th>
              <th v-if="item.length > 0 ">
                {{ $t('Length') }}
              </th>
              <th v-else>
                {{ $t('Quantity') }}
              </th>
            </tr>
          </thead>
          <tbody
            v-for="(item1,index1) in item.materialVariantInWithoutPriceStoreDtos"
            :key="index1"
          >
            <tr>
              <td>
                {{ item1.name }}
              </td>
              <td>
                {{ item1.measurementType }}
              </td>
              <td v-if="item.length > 0 ">
                {{ item1.length }}
              </td>
              <td v-else>
                {{ item1.quantity }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';





export default {
  components: {

  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['orderMovementNumber'],
  data() {
    return {
      modalShow: false,
    };
  },
  computed: {
    ...mapGetters(['getManuStoreDetails']),
  },
  mounted() {

  },
  methods: {
    ...mapActions([]),
    showModal() {
      this.modalShow = true;
    },
    hideModal() {
      this.modalShow = false;
    },
    onCancel() {
      this.$emit('onCancelToggle');
      this.$refs.modal.hide();
    },
    onCancelToggle() {
      this.$emit('onCancelToggle');
    },

  },
};
</script>

<style scoped>
.team_table td {
  padding: 16px 9px !important;
}
.hover-row:hover {
  background-color: rgba(255, 39, 79, 0.1);
}
</style>
